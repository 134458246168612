import { translate } from '@/languages/i18n'

export const ERROR_INVALID_PARAMS = 'ERROR_INVALID_PARAMS'
export const ERROR_FORBIDDEN_OPERATION = 'ERROR_FORBIDDEN_OPERATION'
export const ERROR_EXPIRED = 'ERROR_EXPIRED'
export const INVALID_URL_ERROR = 'INVALID_URL_ERROR'
export const ERROR_PARTICIPATED = 'ERROR_PARTICIPATED'
export const ERROR_SETTINGS_ALREADY_UPDATED = 'ERROR_SETTINGS_ALREADY_UPDATED'

//2022/10/03 CW-Phuong Fix for UAT#3952: SC63(Ph1-R20221005)
const MESSAGE_LIST = {
  VALIDATE_USER_DUPLICATE: translate('VALIDATE_USER_DUPLICATE'),
  VALIDATE_DUPLICATE: translate('VALIDATE_DUPLICATE'),
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE: translate('VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE'),
  VALIDATE_NUMBER_OF_USERS: translate('VALIDATE_NUMBER_OF_USERS'),
  ERROR_CREATE: translate('ERROR_CREATE'),
  FORBIDDEN_OPERATION: translate('FORBIDDEN_OPERATION'),
  VALIDATE_CURRENT_PASSWORD: translate('VALIDATE_CURRENT_PASSWORD'),
  VALIDATE_NOT_CHANGED: translate('VALIDATE_NOT_CHANGED'),
  ERROR_PARTICIPATED: translate('errorParticipated'),
  INVALID_URL_ERROR: translate('INVALID_URL_ERROR'),
  ERROR_REGIST: translate('ERROR_REGIST'),
  ERROR_INVALID_PARAMS: translate('invitedCodeNotFound'),
  EMAIL_REGISTER_CONFIRM_ERROR: translate('EMAIL_REGISTER_CONFIRM_ERROR'),
  VALIDATE_NUMBER_OF_USERS_FOR_ADMIN: translate('VALIDATE_NUMBER_OF_USERS_FOR_ADMIN'),
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE_LIST_FOR_ADMIN: translate('VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE_LIST_FOR_ADMIN'),
  VALIDATE_ALREADY_UPDATED_USER: translate('VALIDATE_ALREADY_UPDATED_USER'),
  VALIDATE_WITHDRAWING_USERS: translate('VALIDATE_WITHDRAWING_USERS'),
  ERROR_SETTINGS_ALREADY_UPDATED: translate('ERROR_SETTINGS_ALREADY_UPDATED'),
  ERROR_NUMBER_OF_SELECTIONS_THRESHOLD_EXCEEDED: translate('ERROR_NUMBER_OF_SELECTIONS_THRESHOLD_EXCEEDED'),
  ERROR_ADDITIONAL_INFORMATIONS: translate('ERROR_ADDITIONAL_INFORMATIONS'),
}

// 2022/09/21 CW-TrungNT Fix for UAT#4415: SF10 (Ph2-R20220922)
const POST_MESSAGE_LIST = {
  VALIDATE_MAX_TAGS: translate('VALIDATE_MAX_TAGS'),
  VALIDATE_SAME_TAGS: translate('VALIDATE_SAME_TAGS'),
}

export const postMessageByCode = (messageCode: string = '') => {
  return POST_MESSAGE_LIST[messageCode]
}

export const getMessage = (messageCode: string) => {
  return MESSAGE_LIST[messageCode] || translate('DEFAULT_ERROR_MESSAGE')
}
